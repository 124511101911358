<template>
    <v-row align="center" justify="center" dark>
        <v-col cols="12" sm="8" md="6">
            <v-tabs background-color="purple darken-2" dark>
                <!-- Tabs -->
                <v-tab>Войти</v-tab>
                <v-tab v-if="isAdminUserExist === false">Создать аккаунт</v-tab>
                <v-spacer />
                <!-- Tab items -->
                <v-tab-item>
                    <login-form 
                        @success="redirectOnHome" 
                        @back="redirectOnHome" 
                    />
                </v-tab-item>
                <v-tab-item v-if="isAdminUserExist === false">
                    <register-form 
                        new-account 
                        @success="redirectOnHome" 
                    />
                </v-tab-item>
            </v-tabs>
        </v-col>
    </v-row>
</template>
<script>
import LoginForm from '@/components/auth/LoginForm.vue'
import RegisterForm from '@/components/auth/RegisterForm.vue'

export default {
    name: 'Login',
    components: { LoginForm, RegisterForm },
    data () {
        return {
            isAdminUserExist: null
        }
    },
    async created () {
        if (this.$store.getters['user/isAuthorized'])
            return this.redirectOnHome()

        this.isAdminUserExist = await this.checkAdminRegistration()
    },
    methods: {
        redirectOnHome() {
            window.location.href = '/';
            return true;
        },
        async checkAdminRegistration () {
            try {
                const { success, data, error } = await this.$store.dispatch('user/checkAdminRegistration')

                if (!success)
                    throw new Error(error)

                return !!data
            } catch (e) {
                console.error(e)
                return false
            }
        }
    }
}
</script>